@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;700;900&display=swap');

*:focus {outline: none;}
body, input, textarea, button {font-family: 'Titillium Web', sans-serif;font-size: 16px;line-height: 28px;}
.cursive {font-family: 'Dancing Script', cursive;}
textarea {resize: vertical;min-height: 120px;max-height: 250px;}
header,footer,.wrapper {max-width: 1280px;}
.wrapper-2 {max-width: 1300px;}
.wrapper-3 {max-width: 1000px;}
.cover {background: url("https://cdn.strategicthinkingforprofit.com/img/podcast/podcast-landing.jpg") center right no-repeat;background-size: cover;}
.cover a {color: #FFF;}
#lander .wrapper {height: 40vw;}
#lander h1 {font-size: 70px;line-height: 70px;}
.article-body p:not(:last-child),
.article-body ul {margin-bottom: 15px;}
.article-body ul {
    box-sizing: border-box;
    list-style-type: disc;
    border-left: 4px solid #4F00C2;
    padding: 10px;
    background: rgba(20,20,20,0.08);
}
.article-body ul li {text-indent: 25px;}
#subscribe-box {width: 800px;height: 500px;}
footer div:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

input,
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

@media only screen and (min-width: 768px) {
    header {
        height: 50px;
    }

    .d-ef {justify-content: flex-end;}
    .d-hf {flex: 0 50%;}
}

@media only screen and (max-width: 767px) {
    header nav ul {display: none;}

    header nav ul.open {
        display: block;
        position: absolute;
        top: 40px;
        right: 0;
        text-align: right;
        z-index: 999;
    }

    header nav ul.open a {
        width: 180px;
        height: 30px;
        line-height: 30px;
        display: block;
        padding: 10px 15px;
        color: #FFF;
    }

    header nav ul.open a:not(.btn) {
        background: #222;
    }

    .m-fw {width: 100%;}
    .m-cf {justify-content: center;}
    .m-tac {text-align: center;}
    .m-2x {font-size: 28px !important;}
}